import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Steps, message, Divider, Collapse, Space } from 'antd';  // Importer Collapse  
import AppLayout from '../../components/layout/AppLayout';
import { useTranslation } from 'react-i18next';
import { TChapter, TCourse } from '../../models/courses/course';
import { useCourseApi } from '../../api/courses/courses';
import { useUserApi } from '../../api/user/userApp';
import { TLessonProgress } from '../../models/user/UserProgression';
import { getAllLessonsFromCourse } from '../../models/courses/courseFunc';
import FloatButtons from '../../components/button/FloatButtons';
import CourseSummaryCard from './CourseSummaryCard';
import ProgressCircle from '../../components/chart/ProgressCircle';

const { Title } = Typography;
const { Step } = Steps;

const CourseDetails: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { courseId } = useParams();  // Récupérer l'ID du cours depuis l'URL
    const { getCoursesDetailsById } = useCourseApi();
    const [course, setCourse] = useState<TCourse | null>(null)
    const { getProgressionUserByCourseId } = useUserApi();
    const [progression, setProgression] = useState<TLessonProgress[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [progressPercent, setProgressPercent] = useState<number>(0);
    const [buttonText, setButtonText] = useState<string>(t('button.enter'));
    const [nextLessonId, setNextLessonId] = useState<number>(0); // <-- Définir l'état `nextLesson`


    useEffect(() => {
        setIsLoading(true);
        Promise.all([getProgressionUserByCourseId(Number(courseId)), getCoursesDetailsById(Number(courseId))])
            .then((response) => {
                const [respProgression, respCourse] = response;

                const courseData: TCourse = respCourse.data;
                const progressionData: TLessonProgress[] = respProgression.data || [];


                setProgression(progressionData);
                setCourse(courseData);

                const allLessons = getAllLessonsFromCourse(courseData)

                const upcomingLesson = allLessons.find(lesson =>
                    lesson && progressionData && !progressionData.some(prog => prog.lesson_id === lesson.id)
                );

                const targetChapter = (courseData?.chapters ?? []).find(chapter =>
                    chapter && chapter.epics && chapter.epics.some(epic =>
                        epic && epic.lessons && epic.lessons && epic.lessons.some(lesson => lesson?.id === upcomingLesson?.id)
                    )
                );
                const targetEpic = targetChapter?.epics?.find(epic =>
                    epic && epic.lessons && epic.lessons && epic.lessons.some(lesson => lesson?.id === upcomingLesson?.id)
                );

                if (targetChapter && targetEpic && upcomingLesson) {
                    setNextLessonId(upcomingLesson.id)
                    setButtonText(`${targetChapter.name} / ${targetEpic.name} / ${upcomingLesson.name}`);
                }

                const progress = allLessons.length > 0 ? ((progressionData.length / allLessons.length) * 100) : 0;
                setProgressPercent(Math.round(progress))
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);




    // Utiliser les ID au lieu des index pour la navigation
    const goToProgress = (lessonId: number) => {
        navigate(`/course-progression/${lessonId}`);
    };

    const navigateBack = () => {
        if (course) {
            navigate(`/subject-courses/${course.subject_id}`);
        } else {
            navigate(-1)
        }
    }
    // Préparer les items pour Collapse
    const collapseItems = (course?.chapters || []).map((chapter: TChapter) => {
        // Créer une liste de toutes les leçons d'un chapitre, peu importe l'épic
        const allLessonsInChapter = (chapter.epics ?? [])
            .filter(epic => epic !== null)
            .flatMap(epic => epic.lessons ?? []);

        // Trouver l'index de la prochaine leçon non effectuée dans le chapitre
        const nextLessonIndex = allLessonsInChapter.findIndex(lesson =>
            !progression.some(prog => prog.lesson_id === lesson.id)
        );
        const progressionInChapter = progression.filter(progress =>
            allLessonsInChapter.some(lesson => lesson.id === progress.lesson_id)
        );

        const percentageProgression: number = allLessonsInChapter.length > 0 ? (progressionInChapter.length / allLessonsInChapter.length) * 100 : 0
        return {
            key: chapter.id.toString(),
            label: (
                <Space>

                    <ProgressCircle percent={percentageProgression} size={30} showPercent={false} strokeColor={undefined} />
                    <div style={{ color: '#931161' }}>
                        {chapter.name}
                    </div>
                </Space>
            ),
            children: (chapter.epics || []).map((epic, indexEpic) => (
                <div key={epic.id} style={{ marginBottom: '32px', overflow: 'hidden' }}>
                    <Title level={5} style={{ marginBottom: '24px', color: "success" }}>
                        {epic.name}
                    </Title>

                    <Steps
                        direction="horizontal"
                        size="small"
                        onChange={(current) => {
                            // Récupérer l'ID de la leçon
                            const lessonId = epic.lessons[current].id;
                            const lessonIndexInChapter = allLessonsInChapter.findIndex((lesson) => lesson.id === lessonId);
                            // Vérifie si la leçon est soit la suivante non effectuée, soit déjà effectuée
                            const isLessonAvailable = nextLessonIndex < 0 || lessonIndexInChapter <= nextLessonIndex;
                            if (isLessonAvailable) {
                                goToProgress(lessonId);
                            }
                        }}
                        style={{
                            marginTop: '12px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'left',
                            rowGap: '20px',
                        }}
                        current={-1}
                    >
                        {(epic.lessons || []).map((lesson) => {
                            // Vérifie si cette leçon a déjà été effectuée
                            const isCompleted = progression.some(prog => prog.lesson_id === lesson.id);
                            // Trouver l'index de la leçon dans le chapitre complet
                            const lessonIndexInChapter = allLessonsInChapter.findIndex((l) => l.id === lesson.id);
                            // Détermine si le bouton de la leçon doit être grisé
                            const isDisabled = nextLessonIndex > 0 && lessonIndexInChapter > nextLessonIndex;

                            return (
                                <Step
                                    key={lesson.id}
                                    title={lesson.name}
                                    status={isCompleted ? 'finish' : 'wait'}
                                    disabled={isDisabled}
                                />
                            );
                        })}
                    </Steps>
                    {indexEpic !== chapter.epics.length - 1 && (<Divider />)}
                </div>
            ))
        };
    });


    return (
        <AppLayout title='Cours' loading={loading}>
            {!course ? (
                <div>{t('text.courseUnavailable')}</div>
            ) : (
                <>
                    <CourseSummaryCard course={course} nextLessonId={nextLessonId} buttonText={buttonText} progressPercent={progressPercent} />

                    {/* Utilisation de items dans Collapse */}
                    <Collapse accordion defaultActiveKey={['-1']} style={{ marginTop: '24px' }} size="large" items={collapseItems} />
                </>
            )}
            <FloatButtons navigationBack={navigateBack} />
        </AppLayout>
    );
};

export default CourseDetails;
