import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, message, Radio, Card, Typography, InputNumber, Space } from 'antd';
import { TLessonAnswerUser, TLessonQuestion } from '../../models/courses/lesson';
import { useReferential } from '../../context/ReferentialContext';
import { CODIFICATION_QUESTION_TYPE } from '../../constant/codification';
import { useUserApi } from '../../api/user/userApp';

const { Title, Text } = Typography;

interface QuestionViewerProps {
  question: TLessonQuestion;
  onNextLesson: () => void;
}

const QuestionViewer: React.FC<QuestionViewerProps> = ({ question, onNextLesson }) => {
  const { getCodificationById } = useReferential();
  const { getQuestionAnswersByQuestionId, saveQuestionAnswers } = useUserApi();
  const [onSave, setOnSave] = useState<boolean>(false);
  const [answers, setAnswers] = useState<TLessonAnswerUser[]>([]);

  const questionType = getCodificationById(question.question_type_id);


  useEffect(() => {
    getQuestionAnswersByQuestionId(question.id)
      .then((resp) => {
        setAnswers(resp.data ?? []);
      })
      .catch((err) => {
        message.error(err.response.data ?? err.message);
      })
      .finally(() => {
        // setIsLoading(false);
      });
    // eslint-disable-next-line
  }, [question])

  const handleAnswersRadioButton = (idAnswer: number) => {
    const answerSelected = question.answers.find((e) => e.id === idAnswer);
    if (answerSelected) {
      const newAnswer: TLessonAnswerUser = {
        id: 0,
        answer_id: answerSelected.id,
        userapp_id: 0,
        question_id: question.id,
        user_input: '',
      };
      setAnswers([newAnswer])
    }
  }

  const handleAnswersCheckBox = (idAnswer: number, remove: boolean = false) => {
    if (remove) {
      // Supprimer une réponse de la liste
      setAnswers((prevAnswers) => prevAnswers.filter((e) => e.answer_id !== idAnswer));
    } else {
      // Trouver la réponse à ajouter
      const answerToAdd = question.answers.find((e) => e.id === idAnswer);
      if (answerToAdd) {
        setAnswers((prevAnswers) => {
          // Ajouter la réponse seulement si elle n'est pas déjà présente
          const isAlreadyAdded = prevAnswers.some((e) => e.answer_id === idAnswer);
          if (!isAlreadyAdded) {
            const newAnswer: TLessonAnswerUser = {
              id: 0,
              answer_id: answerToAdd.id,
              userapp_id: 0,
              question_id: question.id,
              user_input: '',
            };

            return [...prevAnswers, newAnswer];
          }
          return prevAnswers;
        });
      }
    }
  };

  const handleAnswersText = (value: string) => {
    if (question.answers.length > 0) {
      const answerSelected = question.answers[0];
      const newAnswer: TLessonAnswerUser = {
        id: 0,
        answer_id: answerSelected.id,
        userapp_id: 0,
        question_id: question.id,
        user_input: value,
      };
      setAnswers([newAnswer])
    }

  }

  const validateAnswer = () => {
    if (!questionType) {
      return
    }
    // if error, stop
    if (questionType.code === CODIFICATION_QUESTION_TYPE.MULTI_CHOICE || questionType.code === CODIFICATION_QUESTION_TYPE.SINGLE_CHOICE) {
      const incorrectSelected = answers.some((answer) => question.answers.find((answerQuestion) => answerQuestion.id === answer.answer_id && !answerQuestion.is_correct));
      if (incorrectSelected) {
        message.error('Mauvaise réponse : une ou plusieurs réponses incorrectes ont été sélectionnées.');
        return;
      }
    }

    switch (questionType.code) {
      case CODIFICATION_QUESTION_TYPE.MULTI_CHOICE:
        // Vérifier le minimum de réponses correctes
        if (answers.length < question.min_valid_answers) {
          message.warning(
            `Vous devez sélectionner au moins ${question.min_valid_answers} réponses correctes.`
          );
          return;
        }
        break;

      case CODIFICATION_QUESTION_TYPE.TEXT_INPUT:
      case CODIFICATION_QUESTION_TYPE.NUMERIC_INPUT:
        if (question.answers.length > 0 && answers.length > 0) {
          const questionAnswer = question.answers[0];
          const answerUser = answers[0];
          if (questionAnswer.content !== "*" && questionAnswer.content !== answerUser.user_input) {
            message.error('Mauvaise réponse, réessayez.');
            return;
          }
        } else {
          return;
        }
        break;

      default:
        break;
    }


    setOnSave(true);
    saveQuestionAnswers(answers)
      .then(e => {
        setAnswers(e.data)
        onNextLesson();
      })
      .catch((err) => {
        message.error(err.response.data ?? err.message);
      })
      .finally(() => {
        setOnSave(false);
      })
  };

  return (
    <Card
      title={<Title level={4}>Question</Title>}
      style={{ maxWidth: 600, margin: '20px auto', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', padding: 20 }}
    >
      <Space style={{ fontSize: '16px', fontWeight: 500, marginBottom: '20px' }}>
        <Text >{question.content}</Text>

      </Space>

      {questionType?.code === CODIFICATION_QUESTION_TYPE.SINGLE_CHOICE && (
        <Radio.Group
          onChange={(e) => handleAnswersRadioButton(Number(e.target.value))} // Appelle avec l'ID de la réponse
          style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
          value={answers.find(e => question.answers.some(a => a.id === e.answer_id))?.answer_id || null} // Définit la valeur sélectionnée
        >
          {question.answers.map((answer) => (
            <Radio
              key={answer.id}
              value={answer.id}
              style={{ padding: '5px 10px' }}
            >
              {answer.content} {/* Affiche le contenu de la réponse */}
            </Radio>
          ))}
        </Radio.Group>

      )}

      {questionType?.code === CODIFICATION_QUESTION_TYPE.MULTI_CHOICE && (
        <Checkbox.Group
          style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
          onChange={(checkedValues) => {
            // Mettre à jour les réponses sélectionnées en fonction des `checkedValues`
            const selectedIds = new Set(checkedValues.map((value: number) => value));
            question.answers.forEach((answer) => {
              if (selectedIds.has(answer.id)) {
                handleAnswersCheckBox(answer.id, false); // Ajouter si sélectionné
              } else {
                handleAnswersCheckBox(answer.id, true); // Supprimer si désélectionné
              }
            });
          }}
          value={answers.filter(e => question.answers.some(a => a.id === e.answer_id)).map(e => e.answer_id)} // Définit les valeurs sélectionnées

        >
          {question.answers.map((answer) => (
            <Checkbox
              key={answer.id}
              value={answer.id} // Utilise l'ID comme valeur pour la Checkbox
              style={{ padding: '5px 10px' }}
            >
              {answer.content}
            </Checkbox>
          ))}
        </Checkbox.Group>

      )}

      {questionType?.code === CODIFICATION_QUESTION_TYPE.TEXT_INPUT && (
        <div>
          <Input
            placeholder="Entrez votre réponse ici"
            onChange={(e) => handleAnswersText(e.target.value)}
            style={{ borderRadius: '8px', padding: '10px', width: '100%' }}
            value={answers?.[0]?.user_input}
          />
        </div>
      )}
      {questionType?.code === CODIFICATION_QUESTION_TYPE.NUMERIC_INPUT && (
        <div>
          <InputNumber
            placeholder="Entrez votre réponse ici"
            onChange={(e) => handleAnswersText(String(e))}
            style={{ borderRadius: '8px', padding: '10px', width: '100%' }}
            value={Number(answers?.[0]?.user_input)}
          />
        </div>
      )}

      <Button
        type="primary"
        onClick={validateAnswer}
        style={{
          width: '100%',
          marginTop: 20,
          borderRadius: '8px',
          backgroundColor: '#1890ff',
          border: 'none',
        }}
        loading={onSave}
      >
        Soumettre
      </Button>
    </Card>
  );
};

export default QuestionViewer;
