// src/reducers/gameReducer.ts

import { GameState } from './GameState';
import { GameAction } from './GameAction';
import { TTarget } from '../../type/target';

const areTargetsEqual = (t1: TTarget, t2: TTarget): boolean => {
    return (
        t1.x === t2.x &&
        t1.y === t2.y &&
        t1.width === t2.width &&
        t1.height === t2.height
    );
};


export function gameReducer(state: GameState, action: GameAction): GameState {
    switch (action.type) {
        case 'MOVE_CHARACTER':
            return {
                ...state,
                character: {
                    ...state.character,
                    x: action.payload.x,
                    y: action.payload.y,
                    state: action.payload.state,
                    scale: action.payload.scale ?? state.character.scale,
                },
                movementHistory: [...state.movementHistory, { x: action.payload.x, y: action.payload.y }],
            };

        case 'RESET_GAME':
            return {
                ...state,
                character: { ...state.initialCharacter },
                scene: { ...state.initialScene },
                remainingTargets: state.initialTargets.map(target => ({ ...target })),
                movementHistory: [{ x: state.initialCharacter.x, y: state.initialCharacter.y }],
                executionCancelled: false,
                // Réinitialiser d'autres propriétés si nécessaire
            };

        case 'SET_SCENE_BACKGROUND':
            return {
                ...state,
                scene: {
                    ...state.scene,
                    background: action.payload,
                },
            };

        case 'SET_CHARACTER_STATE':
            return {
                ...state,
                character: {
                    ...state.character,
                    state: action.payload,
                },
            };

        case 'SET_EXECUTION_CANCELLED':
            return {
                ...state,
                executionCancelled: action.payload,
            };
        case 'UPDATE_CHARACTER':
            return {
                ...state,
                character: action.payload,
            };

        case 'RESET_MOVEMENT_HISTORY':
            return {
                ...state,
                movementHistory: [{ x: action.payload.x, y: action.payload.y }],
            };

        case 'UPDATE_SCENE':
            return {
                ...state,
                scene: action.payload,
            };

        case 'UPDATE_TARGETS':
            return {
                ...state,
                remainingTargets: action.payload,
            };
        case 'UPDATE_INITIAL_TARGETS':
            return {
                ...state,
                initialTargets: action.payload,
            };

        case 'UPDATE_OR_REMOVE_TARGET': {
            const target = action.payload;

            const updatedTargets = state.remainingTargets
                .map((t) => {
                    if (areTargetsEqual(t, target)) {
                        const newQuantity = t.quantity - 1; // Utiliser t.quantity au lieu de target.quantity
                        return { ...t, quantity: newQuantity };
                    }
                    return t;
                })
                .filter((t) => t.quantity > 0);

            return {
                ...state,
                remainingTargets: updatedTargets,
            };
        }
        default:
            return state;
    }
}
// src/reducers/gameReducer.ts
