import React from 'react';
import { Button, Typography, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getImagePath } from '../../utils/format/valueFormat';
import { PlayCircleOutlined, ReadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TCourse } from '../../models/courses/course';

const { Title, Paragraph } = Typography;

interface CourseSummaryCardProps {
    course: TCourse;
    nextLessonId: number;
    buttonText: string;
    progressPercent: number;
}

const CourseSummaryCard: React.FC<CourseSummaryCardProps> = ({
    course,
    nextLessonId,
    buttonText,
    progressPercent,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goToProgress = (lessonId: number) => {
        navigate(`/course-progression/${lessonId}`);
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#fff',
                boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
            }}
        >
            {/* Image avec overlay de progression */}
            <div
                style={{
                    flex: 1,
                    position: 'relative',
                    overflow: 'hidden',
                    height: '150px',
                }}
            >
                <img
                    alt={course.title}
                    src={getImagePath(course.image)}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
                {/* Overlay de progression */}
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: `${progressPercent}%`,
                        height: '100%',
                        backgroundColor: 'rgba(7, 148, 21, 0.5)',
                    }}
                />
                {/* Texte du pourcentage */}
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: '#000',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        fontWeight: 'bold',
                        fontSize: '45px',
                    }}
                >
                    {progressPercent}%
                </div>
            </div>

            {/* Contenu texte */}
            <div
                style={{
                    flex: 2,
                    padding: '0 24px',
                }}
            >
                <Title level={4} style={{ marginBottom: '8px' }}>
                    <ReadOutlined style={{ marginRight: '8px' }} />
                    {course.title}
                </Title>
                <Paragraph
                    ellipsis={{ rows: 2, expandable: false }}
                    style={{ marginBottom: '8px' }}
                >
                    {course.description}
                </Paragraph>
                <Tag color="blue">
                    {t('text.courseRecommandedAge')}: {course.min_age} - {course.max_age}{' '}
                    {t('text.years')}
                </Tag>
            </div>

            {/* Bouton et commentaire */}
            <div
                style={{
                    flex: 1,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {
                    nextLessonId > 0 && buttonText && (
                        <>
                            <Button
                                icon={<PlayCircleOutlined />}
                                size="large"
                                onClick={() => {
                                    goToProgress(nextLessonId);
                                }}
                                style={{
                                    backgroundColor: '#52c41a',
                                    borderColor: '#52c41a',
                                    color: 'white',
                                    marginBottom: '8px',
                                }}
                            />
                            <Paragraph
                                style={{
                                    margin: 0,
                                    fontSize: '12px',
                                    color: '#888',
                                    marginTop: '8px',
                                    textAlign: 'center',
                                }}
                            >
                                {buttonText}
                            </Paragraph>
                        </>
                    )
                }


            </div>
        </div>
    );
};

export default CourseSummaryCard;
