import React from 'react'
import AppLayout from '../../components/layout/AppLayout'
import ContentViewer from '../../components/editor/ContentViewer'

const ResearchandDevelopment = () => {
    return (
        <AppLayout title='r&d'>
            <></>
            <ContentViewer
                html={`<body><div id="izc8" class="gjs-row"><div id="iv4e" class="gjs-cell"><section class="bdg-sect"><h1 class="heading">Insert title here</h1><p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p></section></div></div><div id="i2s73">Insert your text here</div></body>`}
                css={`{ box-sizing: border-box; } body {margin: 0;}*{box-sizing:border-box;}body{margin-top:0px;margin-right:0px;margin-bottom:0px;margin-left:0px;}.gjs-row{display:table;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;width:100%;}.gjs-cell{width:8%;display:table-cell;height:75px;}#i2s73{padding:10px;}@media (max-width: 768px){.gjs-cell{width:100%;display:block;}}`}
            />
        </AppLayout>
    )
}

export default ResearchandDevelopment