import React, { useEffect, useState } from 'react';
import AppLayout from '../../../../components/layout/AppLayout';
import FloatButtons from '../../../../components/button/FloatButtons';
import { message, Card, Space, Input, Select, InputNumber, Button, Row, Col, Checkbox } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { TLesson, TLessonAnswer } from '../../../../models/courses/lesson';
import { useCourseApi } from '../../../../api/courses/courses';
import { useReferential } from '../../../../context/ReferentialContext';
import { CODIFICATION_QUESTION_TYPE, CODIFICATION_TYPE } from '../../../../constant/codification';

const { Option } = Select;

const LessonForms = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { getLessonDetailsById, saveLessonDetails } = useCourseApi();
    const { getCodificationsByCodeType, getCodificationIdByCodes } = useReferential();
    const questionTypes = getCodificationsByCodeType(CODIFICATION_TYPE.QUESTION_TYPE)

    const lesson: TLesson = location.state?.lesson;
    const [editingLesson, setEditingLesson] = useState<TLesson | undefined>(lesson);
    const [loading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        if (lesson) {
            getLessonDetailsById(lesson.id)
                .then((results) => {
                    const lessonData = results;
                    setEditingLesson(lessonData.data);
                })
                .catch((err) => {
                    message.error(err.response?.data ?? err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        // eslint-disable-next-line
    }, [lesson]);

    const saveLesson = async (): Promise<void> => {
        if (editingLesson) {
            try {
                await saveLessonDetails(editingLesson);
                message.success('Leçon enregistrée avec succès');
                navigate(-1); // Retour à la liste des leçons
            } catch (err: any) {
                message.error(err.response?.data ?? err.message);
            }
        }
    };

    const handleChangeQuestionType = (Id: number) => {
        const selected = questionTypes.find(e => e.id === Id);
        if (editingLesson && selected) {
            setEditingLesson({
                ...editingLesson,
                question: {
                    ...(editingLesson.question || { id: 0, content: '', lesson_id: 0, }),
                    question_type_id: selected.id,
                    min_valid_answers: 1,
                    answers: [],
                    answers_user: []
                }
            });

            if (selected?.code === CODIFICATION_QUESTION_TYPE.TEXT_INPUT || selected?.code === CODIFICATION_QUESTION_TYPE.NUMERIC_INPUT) {
                addAnswer();
            }
        }
    };
    const handleChangeQuestionData = (name: string, value: string | number | null) => {
        if (editingLesson) {
            setEditingLesson({
                ...editingLesson,
                question: {
                    ...(editingLesson.question || { id: 0, content: '', lesson_id: 0, min_valid_answers: 1, question_type_id: 0, answers: [], answers_user: [] }),
                    [name]: value
                }
            });
        }
    };

    const addAnswer = () => {
        if (editingLesson) {
            setEditingLesson(prevLesson => {
                if (!prevLesson) return prevLesson;

                // Définir une nouvelle réponse avec un ID unique
                const newAnswer: TLessonAnswer = {
                    id: 0,
                    content: "",
                    question_id: 0,
                    is_correct: false,
                };

                // Mettre à jour la question avec la nouvelle réponse
                return {
                    ...prevLesson,
                    question: {
                        ...(prevLesson.question || {
                            id: 0,
                            content: '',
                            lesson_id: prevLesson.id,
                            min_valid_answers: 1,
                            question_type_id: 0,
                            answers: [],
                            answers_user: []
                        }),
                        answers: [
                            ...(prevLesson.question?.answers || []),
                            newAnswer
                        ]
                    }
                };
            });
        }
    };

    const onChangeAnswerData = (
        index: number,
        name: keyof TLessonAnswer,
        value: string | boolean | number | null
    ) => {
        if (editingLesson) {
            setEditingLesson(prevLesson => {
                if (!prevLesson || !prevLesson.question) return prevLesson;

                // Copier le tableau des réponses existantes
                const updatedAnswers = [...prevLesson.question.answers];

                // Vérifier que l'index est valide
                if (index >= 0 && index < updatedAnswers.length) {
                    // 
                    if (name === 'is_correct') {
                        // Réinitialiser is_correct si question_type_id === 1
                        if (prevLesson.question.question_type_id === getCodificationIdByCodes(CODIFICATION_TYPE.QUESTION_TYPE, CODIFICATION_QUESTION_TYPE.SINGLE_CHOICE)) {
                            updatedAnswers.forEach((answer, idx) => {
                                if (idx !== index) {
                                    answer.is_correct = false;
                                }
                            });
                        }
                    }


                    // Mettre à jour la réponse spécifique de manière immuable
                    updatedAnswers[index] = {
                        ...updatedAnswers[index],
                        [name]: value
                    };
                }

                // Retourner le nouvel état avec les réponses mises à jour
                return {
                    ...prevLesson,
                    question: {
                        ...prevLesson.question,
                        answers: updatedAnswers
                    }
                };
            });
        }
    };
    const removeAnswer = (index: number) => {
        if (editingLesson) {
            setEditingLesson(prevLesson => {
                if (!prevLesson || !prevLesson.question) return prevLesson;

                // Copier le tableau des réponses existantes
                const updatedAnswers = [...prevLesson.question.answers];

                // Vérifier que l'index est valide
                if (index >= 0 && index < updatedAnswers.length) {
                    // Supprimer la réponse à l'index spécifié
                    updatedAnswers.splice(index, 1);
                }

                // Retourner le nouvel état avec les réponses mises à jour
                return {
                    ...prevLesson,
                    question: {
                        ...prevLesson.question,
                        answers: updatedAnswers
                    }
                };
            });
        }
    };
    return (
        <AppLayout title={lesson?.name || 'Nouvelle Leçon'} loading={loading}>
            <Card title="Question" style={{ marginBottom: 16 }}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Input.TextArea
                        rows={3}
                        placeholder="Question"
                        value={editingLesson?.question?.content}
                        onChange={(e) => {
                            handleChangeQuestionData('content', e.target.value)
                        }}
                    />

                    <Select
                        style={{ width: '200px' }}
                        onChange={handleChangeQuestionType}
                        placeholder="Sélectionnez un type de question"
                        value={editingLesson?.question?.question_type_id}
                    >
                        {questionTypes.map((type) => (
                            <Option key={type.id} value={type.id}>
                                {type.label}
                            </Option>
                        ))}
                    </Select>


                    {editingLesson?.question?.question_type_id === getCodificationIdByCodes(CODIFICATION_TYPE.QUESTION_TYPE, CODIFICATION_QUESTION_TYPE.SINGLE_CHOICE) ||
                        editingLesson?.question?.question_type_id === getCodificationIdByCodes(CODIFICATION_TYPE.QUESTION_TYPE, CODIFICATION_QUESTION_TYPE.MULTI_CHOICE) ? (
                        <>
                            <InputNumber
                                min={1}
                                max={editingLesson?.question?.answers?.length || 1}
                                style={{ width: '200px' }}
                                disabled={editingLesson?.question?.question_type_id === getCodificationIdByCodes(CODIFICATION_TYPE.QUESTION_TYPE, CODIFICATION_QUESTION_TYPE.SINGLE_CHOICE)}
                                placeholder="Nombre minimum de réponses valides"
                                value={editingLesson?.question?.min_valid_answers}
                                onChange={(value) => handleChangeQuestionData('min_valid_answers', value)}
                            />

                            <Card
                                type="inner"
                                title="Réponses possibles"
                                extra={
                                    <Button type="dashed" onClick={addAnswer}>
                                        Ajouter une réponse
                                    </Button>
                                }
                                style={{ marginBottom: 16 }}
                            >
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    {(editingLesson?.question?.answers || []).map((answer, index) => (
                                        <Row key={index} gutter={[16, 16]} align="middle">
                                            <Col xs={24} sm={16}>
                                                <Input
                                                    value={answer.content}
                                                    placeholder={`Réponse ${index + 1}`}
                                                    onChange={(e) => {
                                                        onChangeAnswerData(index, 'content', e.target.value)
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} sm={4}>
                                                <Checkbox
                                                    checked={answer.is_correct}
                                                    onChange={(e) => onChangeAnswerData(index, 'is_correct', e.target.checked)}
                                                >
                                                    Correcte
                                                </Checkbox>
                                            </Col>
                                            <Col xs={12} sm={4}>
                                                <Button danger onClick={() => removeAnswer(index)}>
                                                    Supprimer
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))}
                                </Space>
                            </Card>
                        </>
                    ) : null}

                    {
                        editingLesson?.question?.question_type_id === getCodificationIdByCodes(CODIFICATION_TYPE.QUESTION_TYPE, CODIFICATION_QUESTION_TYPE.TEXT_INPUT) && (
                            <Input
                                style={{ width: '80%' }}
                                value={editingLesson?.question?.answers?.[0]?.content}
                                placeholder='Veuillez indiquer la réponse correcte. (* valide toute réponse)'
                                onChange={(e) => {
                                    onChangeAnswerData(0, 'content', e.target.value)
                                }}
                            />
                        )
                    }
                    {
                        editingLesson?.question?.question_type_id === getCodificationIdByCodes(CODIFICATION_TYPE.QUESTION_TYPE, CODIFICATION_QUESTION_TYPE.NUMERIC_INPUT) && (
                            <InputNumber
                                style={{ width: '80%' }}
                                value={Number(editingLesson?.question?.answers?.[0]?.content)}
                                placeholder='Veuillez indiquer la réponse correcte.'
                                onChange={(value) => onChangeAnswerData(0, 'content', String(value))}
                            />
                        )
                    }
                </Space>
            </Card>

            <FloatButtons save={saveLesson} />
        </AppLayout>
    );
};

export default LessonForms;
