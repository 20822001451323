import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import routes from './routes/routes';
import LoadingBox from './components/loading/LoadingBox';
import { ReferentialProvider } from './context/ReferentialContext';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './routes/ProtectedRoute';

const App: React.FC = () => {
  return (
    <Suspense fallback={<LoadingBox />}>
      <I18nextProvider i18n={i18n}>
        <Router>
          <AuthProvider>
            <ReferentialProvider>
              <Routes>
                {routes.map((route, index) => {
                  const { path, element, isProtected, requiredRoles } = route;
                  const wrappedElement = isProtected ? (
                    <ProtectedRoute requiredRoles={requiredRoles}>
                      {element}
                    </ProtectedRoute>
                  ) : (
                    element
                  );
                  return (
                    <Route
                      key={index}
                      path={path}
                      element={wrappedElement}
                    />
                  );
                })}
              </Routes>
            </ReferentialProvider>
          </AuthProvider>
        </Router>
      </I18nextProvider>
    </Suspense>
  );
};

export default App;
