import React, { useEffect, useState } from 'react';
import AppLayout from '../../../../components/layout/AppLayout';
import WYSIWYG from '../../../../components/editor/WISIWYG';
import FloatButtons from '../../../../components/button/FloatButtons';
import { message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { TLesson } from '../../../../models/courses/lesson';
import { useCourseApi } from '../../../../api/courses/courses';

const LessonSlide = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { getLessonDetailsById, saveLessonDetails } = useCourseApi();

    const lesson: TLesson = location.state?.lesson;
    const [editingLesson, setEditingLesson] = useState<TLesson | undefined>(lesson);
    const [loading, setIsLoading] = useState<boolean>(false);

    const [getContent, setGetContent] = useState<() => { html: string; css: string }>(() => ({ html: '', css: '' }));

    useEffect(() => {
        setIsLoading(true);

        if (lesson) {
            getLessonDetailsById(lesson.id)
                .then((results) => {
                    const lessonData = results;
                    setEditingLesson(lessonData.data);
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line
    }, [lesson]);

    const save = async (): Promise<void> => {
        if (editingLesson) {
            const content = getContent(); // Récupérer le contenu HTML et CSS

            // Mise à jour des données de la leçon avec le contenu de l'éditeur
            let data: TLesson = {
                ...editingLesson,
                slide: {
                    id: 0, // Ajustez selon vos besoins
                    lesson_id: editingLesson.id,
                    html: content.html,
                    css: content.css,
                },
            };

            try {
                await saveLessonDetails(data);
                message.success('Leçon enregistrée avec succès');
                navigate(-1); // Redirection après succès
            } catch (err: any) {
                message.error(err.response?.data ?? err.message);
            }
        }
    };

    return (
        <AppLayout title={lesson.name} loading={loading}>
            <WYSIWYG setGetContent={setGetContent} initialContent={editingLesson?.slide} />
            <FloatButtons save={save} />
        </AppLayout>
    );
};

export default LessonSlide;
