import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

interface ContentViewerProps {
    html: string;
    css: string;
}

const ContentViewer: React.FC<ContentViewerProps> = ({ html, css }) => {
    const [htmlContent, setHtmlContent] = useState('');
    const [cssContent, setCssContent] = useState('');

    useEffect(() => {
        // Nettoyer le HTML pour éviter les attaques XSS
        setHtmlContent(DOMPurify.sanitize(html));
        setCssContent(css);
    }, [html, css]);

    return (
        <div style={{ backgroundColor: "#fff" }}>
            {/* Injecter le CSS dans une balise <style> */}
            <style>{cssContent}</style>

            {/* Afficher le contenu HTML */}
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
    );
};

export default ContentViewer;
