// AuthContext.tsx
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
    getVersion: () => string | null;
    getFullName: () => string | null;
    getToken: () => string | null;
    getRoles: () => string[];
    getTimeRemaining: () => number;
    isRoleExist: (role: string) => boolean;
    login: (token: string, roles: string[], time_remaining: number, fullName: string) => void;
    logout: () => void;
}
// Récupérer la version depuis package.json 

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Fournisseur d'authentification pour envelopper l'application
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const [token, setToken] = useState<string | null>(() => localStorage.getItem('token'));
    const [fullname, setFullname] = useState<string | null>(() => localStorage.getItem('fullname'));
    const [version, setVersion] = useState<string | null>(() => localStorage.getItem('appVersion'));
    const [roles, setRoles] = useState<string[]>(() => {
        const storedRoles = localStorage.getItem('roles');
        return storedRoles ? JSON.parse(storedRoles) : [];
    });
    const [timeRemaining, setTimeRemaining] = useState<number>(() => {
        const storedTime = localStorage.getItem('time_remaining');
        return storedTime ? Number(storedTime) : -1;
    });
    const navigate = useNavigate();

    useEffect(() => {
        const checkVersion = async () => {
            try {
                console.log("Version check");
                const response = await fetch('/version.json', { cache: 'no-store' });
                const data = await response.json();
                const appVersion = data.version;
                const storedVersion = localStorage.getItem('appVersion');

                if (!storedVersion || storedVersion !== appVersion) {
                    // Mettre à jour la version stockée
                    localStorage.setItem('appVersion', appVersion);
                    setVersion(appVersion);
                    // Forcer un rechargement complet de la page
                    console.log("Version différente détectée. Rechargement...");
                    // Forcer un rechargement complet de la page
                    window.location.reload();
                }
            } catch (error) {
                console.error("Erreur lors de la vérification de la version de l'application :", error);
            }
        };

        checkVersion();

        const interval = setInterval(checkVersion, 60 * 60 * 1000); // 1 heure (60 minutes * 60 secondes * 1000 millisecondes)
        return () => clearInterval(interval);
    }, []);

    const login = (newToken: string, userRoles: string[], time_remaining: number, fullName: string) => {
        setToken(newToken);
        setFullname(fullName);
        setRoles(userRoles);
        setTimeRemaining(time_remaining);

        // Enregistrer les informations dans le localStorage pour persistance
        localStorage.setItem('fullname', fullName);
        localStorage.setItem('token', newToken);
        localStorage.setItem('roles', JSON.stringify(userRoles));
        localStorage.setItem('time_remaining', time_remaining.toString());
    };

    const getVersion = () => version;
    const getToken = () => token;
    const getFullName = () => fullname;
    const getRoles = () => roles;
    const getTimeRemaining = () => timeRemaining;
    const isRoleExist = (role: string) => roles.includes(role);

    const logout = () => {
        setFullname(null);
        setToken(null);
        setRoles([]);
        setTimeRemaining(-1);

        ['fullname', 'token', 'roles', 'time_remaining'].forEach(item => localStorage.removeItem(item));

        // Redirection sans rechargement complet
        navigate('/');
    };

    return (
        <AuthContext.Provider value={{ getVersion, getToken, getRoles, login, logout, isRoleExist, getTimeRemaining, getFullName }}>
            {children}
        </AuthContext.Provider>
    );
};

// Hook personnalisé pour utiliser le contexte d'authentification
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
