// src/blocks/move/moveRight.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import i18next from 'i18next';
import { BlocklyFields } from '../BlocklyFields';

export function initRepeatCustomBlock() {
    Blockly.Blocks[BlocklyFields.REPEAT] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.REPEAT,
                "message0": i18next.t('blockly.repeat.message', 'repeat'),
                "args0": [
                    {
                        "type": "field_image",
                        "src": "/images/blocks/repeat.png",  // Chemin relatif vers l'icône de répétition
                        "width": 16,
                        "height": 16,
                        "alt": "*",  // Texte alternatif pour l'icône
                    },
                    {
                        "type": "field_dropdown",
                        "name": "TIMES",
                        "options": [
                            ['1', '1'],
                            ['2', '2'],
                            ['3', '3'],
                            ['4', '4'],
                            ['5', '5'],
                            ['6', '6'],
                            ['7', '7'],
                            ['8', '8'],
                        ]
                    },
                    {
                        "type": "input_statement",
                        "name": "DO"  // Bloc de déclaration pour les blocs à répéter
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": '#5CA65C', // Vert, par exemple
                "tooltip": i18next.t('blockly.repeat.tooltip', 'repeat'),
                "helpUrl": ""
            });
        }
    };

    javascriptGenerator.forBlock[BlocklyFields.REPEAT] = function (block, generator) {
        var branch = generator.statementToCode(block, 'DO');

        var repeats = block.getFieldValue('TIMES');
        return 'for (let count = 0; count < ' + repeats + '; count++) {\n' +
            branch + '}\n';
    };
}
