import React, { useState } from 'react';
import { FloatButton } from 'antd';
import { SaveOutlined, RollbackOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

interface Props {
    save?: () => Promise<void>;
    navigationBack?: () => void;
}

const FloatButtons: React.FC<Props> = ({ save, navigationBack }) => {
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    const handleSave = async () => {
        if (save) {
            setIsSaving(true);
            try {
                await save();
            } catch (error) {
                console.error("An error occurred while saving:", error);
            } finally {
                setIsSaving(false);
            }
        }
    };

    return (
        <FloatButton.Group shape="circle" style={{ insetInlineEnd: 24 }}>
            {save && (
                <FloatButton
                    type="default"
                    icon={isSaving ? <LoadingOutlined spin /> : <SaveOutlined />}
                    onClick={!isSaving ? handleSave : undefined}
                    style={{
                        opacity: isSaving ? 0.5 : 1,
                        pointerEvents: isSaving ? 'none' : 'auto'
                    }}
                />
            )}
            <FloatButton
                type="default"
                icon={<RollbackOutlined />}
                onClick={() => navigationBack ? navigationBack() : navigate(-1)}
            />
            <FloatButton.BackTop visibilityHeight={0} />
        </FloatButton.Group>
    );
};

export default FloatButtons;
