import React, { useEffect, useRef } from 'react';
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';

// Importation des plugins
import grapesjsBlocksBasic from 'grapesjs-blocks-basic';
import grapesjsPresetWebpage from 'grapesjs-preset-webpage';

interface WYSIWYGProps {
    setGetContent: React.Dispatch<React.SetStateAction<() => { html: string; css: string }>>;
    initialContent?: {
        html: string;
        css: string;
    };
}

const WYSIWYG: React.FC<WYSIWYGProps> = ({ setGetContent, initialContent }) => {
    const editorRef = useRef<any>(null);
    const editorContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (editorContainerRef.current) {
            editorRef.current = grapesjs.init({
                container: editorContainerRef.current,
                height: '800px',
                width: '100%',
                fromElement: false,
                storageManager: { type: 'none' },
                plugins: [grapesjsBlocksBasic, grapesjsPresetWebpage],
            });
            // Si du contenu initial est fourni, le charger
            if (initialContent) {
                editorRef.current.setComponents(initialContent.html);
                editorRef.current.setStyle(initialContent.css);
            }
            // Définir la fonction getContent et la fournir au parent
            const getContent = () => {
                return {
                    html: editorRef.current.getHtml(),
                    css: editorRef.current.getCss(),
                };
            };

            setGetContent(() => getContent); // Fournir la fonction au parent
        }

        return () => {
            if (editorRef.current) {
                editorRef.current.destroy();
            }
        };
    }, [setGetContent, initialContent]); // Exécuter lorsque setGetContent change

    return (
        <div style={{ padding: '20px' }}>
            <div ref={editorContainerRef} style={{ border: '1px solid #ddd', minHeight: '600px' }} />
        </div>
    );
};

export default WYSIWYG;
