import React from 'react';
import { TTarget } from '../../type/target';
import { Badge, Tag } from 'antd';  // Importer Badge et Tag d'Ant Design

interface TargetProps {
    target: TTarget;
    editionMode: boolean;
}

const Target: React.FC<TargetProps> = ({ target, editionMode = false }) => {
    // Choisir l'image à afficher en fonction de l'état
    const targetImage = target.state === 'reached' ? target.images.reached : target.images.idle;

    // Vérifier si la cible doit disparaître lorsqu'elle est atteinte
    if (target.state === 'reached') {
        return null; // Ne pas afficher la cible si elle doit disparaître
    }

    // Vérifier si la cible est concerné par la visiblité et est visible 
    if (editionMode === false) {
        if (!target.visible) {
            return null; // Ne pas afficher la cible si elle doit disparaître
        }
    }

    return (
        <div
            style={{
                position: 'absolute',
                top: target.y,
                left: target.x,
                width: `${target.width}px`,
                height: `${target.height}px`,
                backgroundColor: targetImage ? 'transparent' : 'gray',  // Si l'image n'est pas fournie, on affiche un carré gris
                backgroundImage: targetImage ? `url(${targetImage})` : 'none',
                backgroundSize: 'cover',   // Ajuste l'image pour couvrir tout le carré
                transform: `rotate(${target.rotate}deg)`,
                border: editionMode ? '2px dashed green' : 'none', // Bordure noire dashed si editionMode est vrai 
            }}
        >
            {/* Afficher l'ordre si défini */}
            {target.order !== undefined && (
                <Badge
                    count={target.order}
                    style={{
                        position: 'absolute',
                        top: -10,
                        right: -15,
                        backgroundColor: '#52c41a',  // Couleur du badge
                    }}
                />
            )}

            {/* Afficher la quantité si définie */}
            {target.showQuantity && target.quantity !== undefined && (
                <Tag
                    color="blue"
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: -10,
                    }}
                >
                    {target.quantity}
                </Tag>
            )}
        </div>
    );
};

export default Target;
