// src/game/GameLogic.ts

import { useGameContext } from './GameContext';
import { TObstacle } from '../../type/obstacle';
import { TTarget } from '../../type/target';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { TCharacter } from '../../type/character';
import { useEffect, useRef } from 'react';
import { roundToNearest } from '../../../../utils/gen/calcul';

interface GameLogicProps {
    animationPromiseResolverRef: React.MutableRefObject<(() => void) | null>;
    obstacles: TObstacle[];
    autoReset: boolean;
    checkResult: (isValid: boolean) => void;
    autoReach: boolean;
    mustReachAllTargets: boolean;
    respectOrder: boolean;
}

export const useGameLogic = ({
    animationPromiseResolverRef,
    obstacles,
    autoReset,
    checkResult,
    autoReach,
    mustReachAllTargets,
    respectOrder,
}: GameLogicProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useGameContext();
    const stateRef = useRef(state);

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    let collisionMessageDisplayed = false;

    const walkCharacter = async (angle: number): Promise<void> => {
        const { character } = stateRef.current; // Récupérer le dernier `character` 
        return moveCharacter(angle, character.displacement_unit);
    };

    const jumpCharacter = async (angle: number): Promise<void> => {
        const { character } = stateRef.current; // Récupérer le dernier `character`
        return moveCharacter(angle, character.displacement_unit * 2);
    };

    const adjustDistanceForAngle = (angle: number, baseDistance: number): number => {
        // Normaliser l'angle entre 0 et 360 degrés
        const normalizedAngle = ((angle % 360) + 360) % 360;

        // Angles diagonaux (45°, 135°, 225°, 315°)
        const diagonalAngles = [45, 135, 225, 315];

        if (diagonalAngles.includes(normalizedAngle)) {
            // Multiplier la distance par √2 pour les déplacements diagonaux
            return baseDistance * Math.SQRT2;
        } else {
            // Garder la distance de base pour les autres angles
            return baseDistance;
        }
    };


    // Fonction de vérification des collisions
    const checkCollision = (
        nextX: number,
        nextY: number,
        prevCharacterState: TCharacter
    ) => {
        const { scene } = stateRef.current;
        let isColliding = false;
        let adjustedX = nextX;
        let adjustedY = nextY;

        // Vérifier les collisions avec les obstacles
        for (const obstacle of obstacles) {
            if (
                nextX < obstacle.x + obstacle.width &&
                nextX + prevCharacterState.width > obstacle.x &&
                nextY < obstacle.y + obstacle.height &&
                nextY + prevCharacterState.height > obstacle.y
            ) {
                isColliding = true;

                // Ajuster la position en fonction du côté de la collision
                const fromLeft = prevCharacterState.x + prevCharacterState.width <= obstacle.x;
                const fromRight = prevCharacterState.x >= obstacle.x + obstacle.width;
                const fromTop = prevCharacterState.y + prevCharacterState.height <= obstacle.y;
                const fromBottom = prevCharacterState.y >= obstacle.y + obstacle.height;

                if (fromLeft) {
                    adjustedX = obstacle.x - prevCharacterState.width;
                } else if (fromRight) {
                    adjustedX = obstacle.x + obstacle.width;
                } else if (fromTop) {
                    adjustedY = obstacle.y - prevCharacterState.height;
                } else if (fromBottom) {
                    adjustedY = obstacle.y + obstacle.height;
                }
                break;
            }
        }

        // Vérifier les collisions avec les limites de la scène
        if (nextX < 0) {
            adjustedX = 0;
            isColliding = true;
        } else if (nextX + prevCharacterState.width > scene.width) {
            adjustedX = scene.width - prevCharacterState.width;
            isColliding = true;
        }

        if (nextY < 0) {
            adjustedY = 0;
            isColliding = true;
        } else if (nextY + prevCharacterState.height > scene.height) {
            adjustedY = scene.height - prevCharacterState.height;
            isColliding = true;
        }

        return { isColliding, adjustedX, adjustedY };
    };



    const moveCharacter = async (angle: number, distance: number): Promise<void> => {
        // Récupérer le dernier état à chaque appel
        const { character, executionCancelled } = stateRef.current;

        if (executionCancelled) {
            return Promise.resolve();
        }

        const adjustedDistance = adjustDistanceForAngle(angle, distance);

        return new Promise<void>((resolve) => {


            const radians = (angle * Math.PI) / 180;
            const deltaX = adjustedDistance * Math.cos(radians);
            const deltaY = adjustedDistance * Math.sin(radians);

            // Calcul des positions suivantes
            const nextX = roundToNearest(character.x + deltaX);
            const nextY = roundToNearest(character.y + deltaY);

            const { isColliding, adjustedX, adjustedY } = checkCollision(
                nextX,
                nextY,
                character
            );

            const finalX = isColliding ? adjustedX : nextX;
            const finalY = isColliding ? adjustedY : nextY;

            if (isColliding) {
                dispatch({ type: 'SET_CHARACTER_STATE', payload: 'colliding' });
                dispatch({ type: 'SET_EXECUTION_CANCELLED', payload: true });

                if (!collisionMessageDisplayed) {
                    message.error(t('message.collisionOrOutOfBounds'));
                    collisionMessageDisplayed = true;
                }

                if (autoReset) {
                    setTimeout(() => {
                        resetGame();
                        collisionMessageDisplayed = false; // Réinitialiser le drapeau après le reset
                    }, 1000);
                }
                resolve();
            } else {
                // animationPromiseResolverRef est géré dans IGame
                animationPromiseResolverRef.current = resolve;
                dispatch({
                    type: 'MOVE_CHARACTER',
                    payload: {
                        x: roundToNearest(finalX),
                        y: roundToNearest(finalY),
                        state: 'moving',
                        scale: deltaX >= 0 ? 1 : -1,
                    },
                });
                if (autoReach) {
                    reachTargetByXY(finalX, finalY);
                }
                // le resolve est dans l'animation IGame
            }
        });


    };

    const resetGame = () => {
        dispatch({ type: 'RESET_GAME' });
    };
    const areTargetsEqual = (t1: TTarget, t2: TTarget): boolean => {
        return (
            t1.x === t2.x &&
            t1.y === t2.y &&
            t1.width === t2.width &&
            t1.height === t2.height
            // Ajoutez d'autres propriétés si nécessaire pour distinguer les cibles
        );
    };
    // Dans `updateOrRemoveTarget`
    const updateOrRemoveTarget = (target: TTarget) => {
        dispatch({ type: 'UPDATE_OR_REMOVE_TARGET', payload: target });
    };

    const reachTargetByXY = async (x: number, y: number): Promise<void> => {
        const { remainingTargets } = stateRef.current;
        const { character } = stateRef.current; // Récupérer le dernier `character`

        // Trouver la cible atteinte
        const actualTarget = remainingTargets.find((target) => {
            return (
                x < target.x + target.width &&
                x + character.width > target.x &&
                y < target.y + target.height &&
                y + character.height > target.y
            );
        });

        if (actualTarget) {
            if (respectOrder && !areTargetsEqual(actualTarget, remainingTargets[0])) {
                dispatch({ type: 'SET_EXECUTION_CANCELLED', payload: true });
                message.error(t('message.wrongOrder'));
                if (autoReset) {
                    setTimeout(() => {
                        resetGame();
                    }, 1000);
                }
            } else {
                // Mettre à jour ou retirer la cible atteinte
                updateOrRemoveTarget(actualTarget);

                // Vérifier si toutes les cibles ont été atteintes
                const { remainingTargets: updatedRemainingTargets } = stateRef.current;

                if (updatedRemainingTargets.length === 0 && mustReachAllTargets) {
                    checkResult(true);
                    dispatch({ type: 'SET_CHARACTER_STATE', payload: 'wining' });
                }
            }
        }
    };

    const setSceneBackground = (img: string) => {
        const { scene } = stateRef.current;
        dispatch({
            type: 'SET_SCENE_BACKGROUND',
            payload: {
                img,
                repeat: scene.background?.repeat || false,
                size: scene.background?.size || 'cover',
            },
        });
    };

    const pauseCharacter = () => {
        dispatch({ type: 'SET_CHARACTER_STATE', payload: 'paused' });
    };

    const reachTarget = async (): Promise<void> => {
        const { character } = stateRef.current; // Récupérer le dernier `character`
        await reachTargetByXY(character.x, character.y);
    };

    const isDone = async () => {

        // Attendre que l'état soit mis à jour
        await new Promise<void>((resolve) => setTimeout(resolve, 0));
        const { executionCancelled } = stateRef.current;

        if (executionCancelled) {
            return Promise.resolve();
        }
        const { initialTargets, remainingTargets } = stateRef.current;
        if (initialTargets.length > 0) {
            let success =
                (mustReachAllTargets && remainingTargets.length === 0) ||
                (!mustReachAllTargets && remainingTargets.length < initialTargets.length);

            checkResult(success);
            if (success) {
                dispatch({ type: 'SET_CHARACTER_STATE', payload: 'wining' });
            } else {

                message.warning(t('message.bug'));
                if (autoReset) {
                    setTimeout(async () => {
                        resetGame();
                    }, 500);
                }
            }
        } else {
            // Si aucune cible n'est définie, considérez que l'exercice est terminé avec succès
            checkResult(true);
        }
    };

    return {
        walkCharacter,
        jumpCharacter,
        moveCharacter,
        resetGame,
        reachTargetByXY,
        setSceneBackground,
        pauseCharacter,
        reachTarget,
        isDone,
    };
};
